* {
  box-sizing: border-box;
}

html {
  font-size: 8px;
}

body {
  margin: 0;
  font: 2rem "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

a {
  text-decoration: none;
}
